<template>
  <FocusTrap>
    <div class="card" id="fuelpurchase_card" >

    <WindowTitleBar title="Fuel Purchase Invoice Creation" @loadData="loadData" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Supplier</label>
            <select id="cmbledger" ref="el_ledger" class="form-control" autofocus required="" v-if="voucher"  v-model="voucher.ledger_id" @change="enableAddButton" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
        </div>

        <div class="col-md-3">
        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Invoice No</label>
            <input type="text" class="form-control text-center" placeholder="Invoice No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.inv_no">
          </div>
        </div>


        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Invoice Date</label>
            <input type="date" class="form-control text-right" v-if="voucher" v-model="voucher.inv_date" placeholder="Invoice Date" >
          </div>
        </div>

      </div>

      <div class="row">

        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
              <tr>
                <th style="width:50px;">S.No</th>
                <th >Item</th>
                <th style="width:75px; text-align: right;">Qty</th>
                <th style="width:100px; text-align: right;">Rate</th>
                <th style="width:100px; text-align: right;">Taxable</th>
                <th style="width:100px; text-align: right;">DLY</th>
                <th style="width:100px; text-align: right;">TAX%</th>
                <th style="width:100px; text-align: right;">Tax Amt</th>
                <th style="width:80px; text-align: right;">Addi Tax</th>
                <th style="width:150px; text-align: right;">Net Amount</th>
                <th style="width: 30px;">Action</th>
              </tr>
            </thead>
            <tbody v-for="(detail,index) in voucher.list" >
              <FuelInvoiceDetailRow :tanks="tanks" :items="fuels" :myindex="index" :myrow="detail" v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow"  />
            </tbody>
            <tfoot>
              <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow"  :disabled="addRowButtonDisabled">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td class="text-right" style="padding: 0px;" >{{ rowQtyTotal }}</td>
              <td class="text-right" style="padding: 0px;" ></td>
              <td class="text-right" style="padding: 0px;" >&#8377;<span >{{ indianFormat(rowTaxableTotal) }}</span></td>
              <td class="text-right" style="padding: 0px;" >&#8377;{{ indianFormat(rowDlyTotal) }}</td>
              <td class="text-right" style="padding: 0px;" ></td>
              <td class="text-right" style="padding: 0px;" >&#8377;<span >{{ indianFormat(rowTaxTotal) }}</span></td>
              <td class="text-right" style="padding: 0px;" ><span>{{ indianFormat(rowAddiTaxTotal) }}</span></td>
              <td class="text-right" style="padding: 0px;" >&#8377;<span >{{ indianFormat(rowAmountTotal) }}</span></td>

              <td></td>

            </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Vehicle No</label>
            <input  type="text" class="form-control text-uppercase" placeholder="Vehicle No"  v-if="voucher" v-model="voucher.vehicle_no" maxlength="12" />
          </div>
        </div>


        <div class="col-md-6">
          <h3 style="font-size: 48px;color: blue">
            <!--voucher.gross_amt-->
            &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(voucher.round_off) ) }}</span>
          </h3>
        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">
            <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
            <div class="col-md-8">
              <input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off" >
            </div>
          </div>

          <div class="form-group row">
            <!--            <label class="col-form-label col-md-4 font-weight-semibold">TDS</label>-->
            <!--            <div class="col-md-8">-->
            <!--              <input id="txttds" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.tds_amt" >-->
            <!--            </div>-->
          </div>


            <SaveButton title="Save" @handle_save_action="saveVoucher" style="margin: 1em;"></SaveButton>
            <CloseButton title="Clear" @handle_close_action="clear" ></CloseButton>

        </div>
      </div>
    </div>


  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import numeral from 'numeral'
  import FuelInvoiceDetailRow from './FuelInvoiceDetailRow.vue'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'
  import {store} from '@/store/store.js'
  import SaveButton from '../../../components/core/buttons/SaveButton'
  import CloseButton from '../../../components/core/buttons/CloseButton'

  export default {
    name: 'FuelInvoiceForm',
    components: {
      CloseButton,
      SaveButton,
      FuelInvoiceDetailRow,
      WindowTitleBar
    },
    store,
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}')
      }
    },
    beforeMount () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      if( this.myvoucher.id > 0 ) {

        self.$data.detailItems = [{"id":101,"name":"Petrol","hsn":"","gstrate":0,"salerate":76.06,"porder":-4},{"id":102,"name":"Diesel","hsn":"","gstrate":0,"salerate":69.9,"porder":-2},{"id":103,"name":"Speed","hsn":"","gstrate":0,"salerate":79.03,"porder":-1}];
        self.$data.items = new Map();

        self.$data.detailItems.forEach(function (itm) {
          Object.freeze(itm);
          self.$data.items.set(itm.id, itm);
        });

        this.voucher = this.myvoucher;
        this.sumRowAmountTotal();
        this.voucher.round_off = this.voucher.round_off.toFixed(2);

      }

    },
    data () {
      return {
        addRowButtonDisabled:false,
        readonly: false,
        ledgers:[],
        tanks:[],
        detailItems:[],
        fuels : [],
        items : new Map(),
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowDlyTotal:0.0,
        rowTaxTotal:0.0,
        rowAddiTaxTotal:0.0,
        rowTaxableTotal:0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}'),
      }
    },
    created () {
      const self = this;
      self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      try {

        self.loadData();
        self.loadTanks();

        if (self.$data.voucher.inv_date === '0001-01-01' ||  self.$data.voucher.inv_date === '2000-01-01') {
          self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
          self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
        }

        $('#cmbledger').focus();
        self.$refs.el_ledger.focus();

      }catch (e) {
        alert(e);
      }
    },
    methods:{
      setTitle() {
        return this.voucher.id == 0 ? "Fuel Purchase Invoice Creation" : "Doc No: " + this.voucher.doc_no + "  Fuel Purchase Invoice Updation";
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis () {
        if (this.voucher.id > 1) {
          this.$emit('fuleinvoice_window_closed');
          this.$data.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}');
        } else {
          this.$router.push('/');
        }
      },
      enableAddButton(){
        const self = this;
        self.ledgers.forEach(function (ldgr) {
          if(self.$data.voucher.ledger_id == ldgr.id){
            self.$data.voucher.ledger =  JSON.parse(JSON.stringify(ldgr));
          }
        });

        this.$data.voucher.list = [];
        this.$data.addRowButtonDisabled = (this.$data.voucher.ledger_id < 1);

        // self.addRow();
      },
      addRow(){
        try {
          const self = this;
          self.$data.voucher.list.push(JSON.parse('{"idx":0,"id":0,"item_id":0,"item":{"id":0,"name":"","unit":6},"unit":2,"rate":0,"qty":0,"taxable_amt":0,"dly_amt":0,"others_amt":0,"tax_rate":0,"tax_amt":0,"add_vat":0,"tcs_amt":0,"grs_amt":0,"net_amt":0,"tankdetails":[]}'));
          setTimeout(function(){
            $("tbody>tr").last().find("td:eq(1) select").focus();
          }, 99);

        }catch (e) {
          alert(e);
        }
      },
      calculate_gst(detail){

        let taxrate = detail.gst_rate;
        let tax_value = Math.round(detail.qty * detail.rate * (taxrate / 100) * 100);
        if (tax_value % 2 !== 0) {
          tax_value++;
        }
        tax_value = tax_value / 100;

        detail.tax_amt = tax_value;

        detail.igst_amt = 0;
        detail.sgst_amt = tax_value / 2;
        detail.cgst_amt = tax_value / 2;
      },
      onrow_change(rowIndex, colIndex) {
        const self = this;
        let tax_value = 0;

        let detail = self.$data.voucher.list[rowIndex];
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowDlyTotal = 0.0;
        self.$data.rowTaxTotal = 0.0;
        self.$data.rowAddiTaxTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {

          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowTaxTotal += parseFloat(detail.tax_amt);
          self.$data.rowDlyTotal += parseFloat(detail.dly_amt);
          self.$data.rowAddiTaxTotal += parseFloat(detail.add_vat);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowDlyTotal = 0.0;
        self.$data.rowTaxTotal = 0.0;
        self.$data.rowAddiTaxTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowTaxTotal += parseFloat(detail.sgst_amt);
          self.$data.rowDlyTotal += parseFloat(detail.cgst_amt);
          self.$data.rowAddiTaxTotal += parseFloat(detail.add_vat);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },
      clear(){
        $('#btnSave').prop('disabled', false);
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}');
        self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.inv_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.list = [];

        self.$data.rowAmountTotal = 0.0;
        self.$data.addRowButtonDisabled = false;
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowDlyTotal = 0.0;
        self.$data.rowTaxTotal = 0.0;
        self.$data.rowAddiTaxTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        $('#cmbledger').focus();
      },
      loadData() {
        const self = this;


        $('#fuelpurchase_card').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        if( self.$data.items.size == 0 ){
          //Fetch Detail Ledgers
          self.$data.detailItems = [{"id":101,"name":"Petrol","hsn":"","gstrate":0,"salerate":76.06,"porder":-4},{"id":102,"name":"Diesel","hsn":"","gstrate":0,"salerate":69.9,"porder":-2},{"id":103,"name":"Speed","hsn":"","gstrate":0,"salerate":79.03,"porder":-1}];
          self.$data.items = new Map();

          self.$data.detailItems.forEach(function (itm) {
            Object.freeze(itm);
            self.$data.items.set(itm.id, itm);
          });
        }

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Oil Company
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/oilcompany`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.ledgers.push(resp.data);
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(()=> {
          $('#fuelpurchase_card').unblock();
        });


        $('#fuelpurchase_card').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        //fuels
        self.$data.fuels = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/fuels`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.fuels = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(()=> {
            $('#fuelpurchase_card').unblock();
        });


      },
      loadTanks () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.tanks = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/tanks`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (Array.isArray(resp.data)) {
              self.$data.tanks =  resp.data;
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' });
          }

        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        });
      },
      saveVoucher(){
        const self = this;

        try{
          $('#fuelpurchase_card').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
          myvoucher.finyear = store.state.user.finyear;
          myvoucher.brn_id =  parseInt(self.$store.state.user.branch.id);
          myvoucher.inv_date = moment(self.$data.voucher.inv_date).format('YYYY-MM-DD');
          myvoucher.doc_date = moment(self.$data.voucher.inv_date).format('YYYY-MM-DD');

          myvoucher.round_off = parseFloat(self.$data.voucher.round_off);
          myvoucher.gross_amt = parseFloat(self.$data.rowAmountTotal);
          myvoucher.vat_amt = parseFloat(self.$data.rowTaxTotal) + parseFloat(self.$data.rowAddiTaxTotal);
          myvoucher.net_amt = myvoucher.gross_amt + myvoucher.round_off;


          myvoucher.list.forEach(function (detail){
            detail.tax_rate = parseFloat(detail.tax_rate);
            detail.qty = parseFloat(detail.qty);
            detail.unit = 6;
            detail.rate = parseFloat(detail.rate);
            detail.taxable_amt = parseFloat(detail.taxable_amt);
            detail.tax_rate = parseFloat(detail.tax_rate);
            detail.tax_amt = parseFloat(detail.tax_amt);
            detail.dly_amt = parseFloat(detail.dly_amt);
            detail.net_amt = parseFloat(detail.net_amt);

            detail.tankdetails.forEach((row)=>{
              row.qty = parseFloat(row.qty);
              row.open_dip = parseFloat(row.open_dip);
              row.close_dip = parseFloat(row.close_dip);
              row.open_qty = parseFloat(row.open_qty);
              row.close_qty = parseFloat(row.close_qty);
              row.diff_qty = parseFloat(row.diff_qty);
            });
          });

          myvoucher.vehicle_no = myvoucher.vehicle_no.toUpperCase().trim();

          const requestOptions = {
            method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT' ),
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };


          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/fuelpurchase`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              swal({ title: 'Great', type: 'success', text: resp.msg , onClose: () => { $('#cmbledger').focus()}, timer:1500 });
              $('#fuelpurchase_card').unblock();
              self.clear();
              self.$emit('fuel_purchase_saved', resp.data);
            }else{
              $('#btnSave').prop('disabled', false);
              $('#fuelpurchase_card').unblock();
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error", onClose: () => { $('#cmbledger').focus()}, timer:3000 });
            }
          }).catch(function (err) {
            $('#fuelpurchase_card').unblock();
            $('#btnSave').prop('disabled', false);
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error", onClose: () => { $('#cmbledger').focus()}, timer:3000 });
          });

        }catch (e) {
          console.log(e);
          alert(e);
        }finally {

        }

      },
      showTankDetail(){
        this.$modal.show('tank-window')
      },
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
